
.media {
    width: auto;
    height: 300px;
  }
  
  .movie {
    /*padding: 5px 25px 10px 25px;*/
    width: 300px;
    margin: 10px;
  }
  